import React from "react";
import StandardPageTemplate from "../../templates/standard-page";
import PageWrapper from "../../components/core/PageWrapper";
import Accordion from "../../components/Accordion";
import { Link } from "gatsby";

const PAGE_PROPS = {
  title: "Frequently Asked Questions",
  desc:
    "Find answers to common questions about the Amos Institute, the Bredesen Protocol and more.",
};

const WHERE_IS_THE_INSTITUTE = {
  question: "Where is the Amos Institute located?",
  answer: function() {
    return (
      <span>
        The simple answer is, we are everywhere! The Amos Institute is the
        world’s leading online platform that assists people with the
        implementation of the nutrition and lifestyle components of the Bredesen
        Protocol all around the world. Our first office was originally located
        in Los Angeles, but with the demand for the Bredesen Protocol around the
        world, we have perfected a plan which allows you to receive private
        one-on-one consultations and all of our course materials and lessons
        from the comfort of your own home.
      </span>
    );
  },
};

const IS_ALZHEIMERS_TREATED_THE_SAME = {
  question: "Is Alzheimer's always treated the same way?",
  answer: function() {
    return (
      <span>
        No, there is not one way to treat Alzheimer's. In fact, most people are
        not aware that there are different types of Alzheimer's. One of the
        unique aspects of the Bredesen Protocol is that it addresses the
        underlying etiologies of Alzheimer's disease. Dr. Bredesen has
        identified at least 36 different contributors for Alzheimer's disease.
        The goal is to determine which of these contributors have caused the
        disease in each individual client and address them accordingly. Because
        client’s contributors vary, the treatment plan varies to some degree as
        well.
      </span>
    );
  },
};

const WHAT_IS_APOE = {
  question: "What is ApoE and how does ApoE affect my risk for Alzheimer's?",
  answer: function() {
    return (
      <span>
        ApoE, or Apolipoprotein E, is a gene. Three common variants of this gene
        exist, and they are called ApoE2, ApoE3, and ApoE4. Every person is born
        with two copies of ApoE. We receive one ApoE gene from each parent. ApoE
        variants are relevant to Alzheimer’s disease because the ApoE variants
        that you carry alter your genetic risk of developing Alzheimer's.
        <br />
        <br />
        <Link to={"/what-is-apoe/"}>
          Click here for our full article on APOE →
        </Link>
      </span>
    );
  },
};

const IF_MY_PARENT_HAS_ALZHEIMERS = {
  question: "If one of my parents has Alzheimer’s, will I also get it?",
  answer: function() {
    return (
      <span>
        Not necessarily. If one or both of your parents had a copy of ApoE4 that
        they passed on to you, your genetic risk is elevated. However, your
        genetics do not determine what will happen to you and your health. We
        can greatly influence our genes through our lifestyle choices. This is
        why at the Amos Institute, we take an aggressive and proactive approach
        to treating and preventing Alzheimer's disease.
      </span>
    );
  },
};

const IF_I_EAT_HEALTHY = {
  question:
    "My family and I already eat healthy. Will the program still benefit us?",
  answer: function() {
    return (
      <span>
        Yes. There is an insurmountable amount of misguided nutrition
        information in the public forum. "Eating healthy" is unfortunately not
        what most people think it is. And beyond the misinformation issue, our
        food supply is laden with hidden toxicants, many of which are not even
        listed on nutrition labels.
        <br />
        <br />
        At the Amos Institute, we specialize in a nutrition program that is
        specifically designed for preventing or reversing chronic disease. We
        take this idea of 'healthy eating' to a whole new level. For nearly all
        of our clients, including those that formerly considered themselves
        “healthy eaters”, our nutrition program is both rigorous and
        challenging.
      </span>
    );
  },
};

const AFRAID_OF_GETTING_IT_ONE_DAY = {
  question:
    "Nobody in my family currently has Alzheimer’s disease, but it runs in our family and I am afraid that my children and I might get it one day. Will the Amos Institute program help us prevent it?",
  answer: function() {
    return (
      <span>
        Yes. Alzheimer’s is a terrifying disease and feeling worried about one
        day developing Alzheimer’s is normal. The good news is that even with
        strong genetic predisposition, you can help prevent the disease in you
        and your family by following our program. The concept behind how this
        works is called ‘epigenetics’ in the science community. Through factors
        like diet, exercise, stress management, and sleep, we all have the
        ability to turn on and off various genes in our body's genome, through a
        process called methylation. With our program, we are using epigenetics
        in a favorable way, by signaling to our body to turn on genes that will
        help prevent cognitive decline and turn off genes that contribute to it.
      </span>
    );
  },
};

const DO_YOU_WORK_WITH_THE_ASSOCIATION = {
  question: "Do you work with the Alzheimer’s Association?",
  answer: function() {
    return (
      <span>
        We are not affiliated with the Alzheimer’s Association. Unfortunately,
        the Alzheimer’s Association continues to promote the myth that there is
        no treatment available to stop the progression of Alzheimer’s disease.
        The published scientific results of the Bredesen Protocol demonstrate
        that Alzheimer's can be prevented, stopped, and reversed. We believe
        that the financial incentives to the big pharmaceutical companies from
        promoting drugs to Alzheimer’s patients has influenced many such
        organizations that claim to help the Alzheimer's cause (even though it
        is well documented that these drugs provide no hope for regaining
        cognition).
      </span>
    );
  },
};

const OFFICE_HOURS = {
  question: "What kind of questions can I ask during Office Hours?",
  answer: function() {
    return (
      <span>
        The purpose of the Office Hours offered in the Cognitive Health Program
        is to provide an opportunity to ask general questions about the program,
        share stories, recipes and ideas with others who are changing their
        lives through the Bredesen Protocol. All questions are welcome, as long
        as they do not address your personal information, including your
        genetics, your labs, and your unique situation. Keeping questions to a
        general nature allows us to protect your privacy.
      </span>
    );
  },
};

const COGNOSCOPY = {
  question:
    "Does the Amos Institute perform the ReCODE testing that Dr. Bredesen describes as the ‘Cognoscopy’?",
  answer: function() {
    return (
      <span>
        No. The Amos Institute does not offer any medical testing. The tests
        that Dr. Bredesen refers to as the ‘Cognoscopy’ are offered direct to
        consumer through his company, Apollo Health. Apollo Health packages the
        results into a ReCODE report, which is the gold standard results summary
        of the Bredesen Protocol. More information can be found on their
        website. Alternatively, we have worked with many clients who choose to
        get the tests run by their own physician. For more information on how we
        will review with you your ReCODE report or the tests run by your
        physician in order to tailor the nutrition and lifestyle components of
        the protocol to meet your specific needs, see our list of{" "}
        <Link to={"/pricing/"}>Comprehensive Services</Link>.
      </span>
    );
  },
};

const INSURANCE = {
  question: "Do you take insurance?",
  answer: function() {
    return (
      <span>
        No. The fees of the Amos Institute are not currently covered by
        insurance. It is our hope that our lobbying efforts will one day change
        this. Unfortunately, at the current time our healthcare system places
        strong restrictions on the type of care you can receive. We are able to
        provide you with our standard billing invoice, but we do not create a
        healthcare superbill for our plans.
      </span>
    );
  },
};

const FAQS = [
  WHERE_IS_THE_INSTITUTE,
  IS_ALZHEIMERS_TREATED_THE_SAME,
  WHAT_IS_APOE,
  IF_MY_PARENT_HAS_ALZHEIMERS,
  IF_I_EAT_HEALTHY,
  AFRAID_OF_GETTING_IT_ONE_DAY,
  DO_YOU_WORK_WITH_THE_ASSOCIATION,
  OFFICE_HOURS,
  COGNOSCOPY,
  INSURANCE,
];

const FAQ_Page = () => {
  return (
    <StandardPageTemplate props={PAGE_PROPS}>
      <PageWrapper>
        <>
          <div className="py-4 lg:py-10 px-6 lg:px-0 w-auto lg:w-10/12 mx-auto mb-10 divide-y-2 divide-gray-200">
            <h1 className="text-center text-3xl md:text-6xl px-0 lg:px-14 xl:px-18 max-w-screen-sm font-black  mx-auto">
              Frequently Asked Questions
            </h1>
          </div>
          <dl className="mt-2 mb-16 space-y-6 divide-y divide-gray-200">
            {<Accordion faqs={FAQS} />}
          </dl>
        </>
      </PageWrapper>
    </StandardPageTemplate>
  );
};

export default FAQ_Page;
